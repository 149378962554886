<template>
  <dialog-form-list
    :config="config"
    :title="title"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  >
  </dialog-form-list>
</template>

<script>
export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      title: this.$t('keyDistribution.Renewal'),
      config: [
        // lang:密钥有效期（年）
        {
          label: this.$t('keyDistribution.validMonths'),
          prop: 'validNum',
          rules: {
            noNull: true,
             type: 'positiveInt',
          },
        },
      ],

    }
  },
  methods: {
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      console.log(this.tableRow)
      formData.id = this.tableRow.id
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.secretKeyRenewal,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.renewalSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },

  },
}
</script>
